.logoSliderimages {
	padding-bottom: 40px;
	.brandLogoDesktop {
		display: block;
	}
	.brandLogoMobile {
		display: none;
	}
}
.logoSliderheading {
	padding: 0;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
	.logoSlider {
		.logoSliderheading {
			h2 {
				font-size: 20px!important;
				font-weight: 600!important;
				letter-spacing: 0.03rem;
			}
		}
	}
	.logoSliderimages {
		.brandLogoDesktop {
			display: none;
		}
		.brandLogoMobile {
			display: block;
		}
	}
}