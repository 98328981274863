.scrollTextToMoveImage {
    background-color: rgb(33, 36, 38);
    color: rgb(248, 248, 248);
    min-height: 100vh;
    position: relative;
    padding: 150px 20px 150px;
    display: flex;
    justify-content: space-between;
}

.ugMobilSilderSection {
    .ugMobileTextSectionWrap {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: end;
        justify-content: flex-end;
        .ugMobilrSilderTextSection {
            margin-bottom: 100px;
            margin-top: 250px;
            h2 {
                font-size: 3.3vw !important;
                line-height: 4vw !important;
                letter-spacing: 0.03rem;
                white-space: pre-line;
                opacity: 0.9;
            }
            h3 {
                font-size: 23px;
                letter-spacing: .03rem;
                line-height: 35px;
                white-space: pre-line;
                max-width: 630px;
                margin-top: 16px;
                margin-bottom: 25px;
            }
            button {
                letter-spacing: .03rem;
                font-size: 22px;
                padding: 15px 25px;
                border-radius: 50px;
                background: #fff;
                border: 1px solid #fff;
                color: #000;
                font-weight: 600;
            }
            p {
                font-size: 18px;
                letter-spacing: 0px;
                line-height: 28px;
                white-space: pre-line;
                max-width: 600px;
                margin-top: 52px;
            }
        }
    }
    .ugMobileSilderMobileWrap {
        .ugMobilrSilderMobileSection {
            background-color: rgb(39, 43, 45);
            border-radius: 36px;
            height: 100%;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            overflow: hidden;
            img {
                opacity: 1 !important;
                transform: translate3d(0%, 15px, 0px) !important;
                width: 300px !important;
                border-radius: 25px;
                padding-bottom: 30px!important;
            }
        }
    }
}
.ScrollSectionForMobile {
    display: none;
}
.ScrollSectionForDesktop {
    display: block;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .scrollTextToMoveImage {
        background-color: #212426;
        color: #f8f8f8;
        min-height: 100vh;
        position: relative;
        padding: 50px 0px 0px;
        display: flex;
        justify-content: space-between;
    }
    
    .ScrollSectionForMobile {
        .headingScrollSection {
            padding: 0% 5% 10% 7%;
            h2 {
                font-size: 26px !important;
                line-height: 32px !important;
                letter-spacing: 0.03rem;
                padding: 0px 15px !important;
            }
            h6 {
                padding: 0px 15px;
                img {
                    width: 175px!important;
                }
            }
        }
        .discriptionScrollSection {
            p {
                font-size: 4.5vw;
            }
            padding: 45px 30px 45px 45px;
        }
        display: block;
            .ugMobilrSilderMobileSection {
                background-color: rgb(39, 43, 45);
                border-radius: 36px;
                height: 100%;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                overflow: hidden;
                img {
                    opacity: 1 !important;
                    transform: translate3d(0%, 15px, 0px) !important;
                    width: 300px !important;
                    border-radius: 25px;
                    padding-bottom: 30px!important;
                }
            }
    }
    .ScrollSectionForDesktop {
        display: none;
    }
}