.ugAboutCp {
  color: rgb(255, 255, 255);
  padding: 100px 0px;
    h6 {
      font-size: 1.3vw;
      letter-spacing: 0.75px;
      line-height: 1vw;
      margin-bottom: 24px;
      color: white;
      opacity: 0.9;
    }
  p {
    font-size: 1.2vw;
    letter-spacing: 0.75px;
    line-height: 1.8vw;
    color: #b6b6b6;
    /* opacity: 0.7; */
    margin-bottom: 0px !important;
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .ugAboutCp {
    color: rgb(255, 255, 255);
    padding: 70px 15px;
    h6 {
      line-height: 22px;
      font-size: 4vw;
    }
    p {
      font-size: 3.5vw;
      letter-spacing: 0.75px;
      line-height: 5.5vw;
    }
  }
}