.reviewTextSection {
    padding-left: 13%;
    padding-right: 13%;
    text-align: center;
    p {
        color: #ffffff;
    }
    h6 {
        color: #ffffff;
        margin-top: -20px;
    }
    h4 {
        font-weight: 600;
        letter-spacing: 0.03rem;
        color: #ffffff;
        font-size: 20px!important;
        opacity: 0.9;
    }
}
.review {
    .reviewGoogleImg {
        padding-top: 30px;
    }
    p {
        margin-bottom: -20px;
        color: #ffffff;
        letter-spacing: 0.03rem;
        font-size: 1.05vw;
        line-height: 1.5vw;
        opacity: 0.8;
    }
    img {
        margin-top: 0px!important;
    }
}

.testimonialForDesktop {
    display: block;
}
.testimonialForMobile {
    display: none;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .testimonialForMobile {
        display: block;
    }
    .testimonialForDesktop {
        display: none;
    }

    .reviewTextSection {
        padding-left: 0%;
        padding-right: 3%;
        text-align: center;
        p {
            color: #ffffff;
        }
        h6 {
            color: #ffffff;
            margin-top: -45px;
        }
        h4 {
            font-weight: 600;
            letter-spacing: 0.03rem;
            color: #ffffff;
            font-size: 20px!important;
            opacity: 0.9;
        }
    }
    .review {
        p {
            margin-bottom: 13px;
            color: #ffffff;
            letter-spacing: 0.03rem;
            font-size: 15px;
            line-height: 1.7rem;
            opacity: 0.8;
        }
        img {
            margin-top: -25px!important;
        }
    }
}